<template>
	<screen size="large" class="brand-onboarding">
		<template slot="title">
			<div style="padding: 0 15px 0 15px">
				<h2 style="text-align: left">Power Up Your Brand</h2>
			</div>
		</template>
		<template slot="body">
			<div
				class="px-10"
				style="display: flex; flex-direction: row; width: 100%; "
			>
				<div class="body-left pr-10" style="flex: 6">
					<p class="mb-8">
						Increase donations by personalizing your brand profile
						and letting donors know what you are all about. Give
						donors confidence and activate your custom RoundUp URLs
						by adding your logo, a cover image, and your mission
						statement.
					</p>
					<p class="mb-10">
						<strong>
							Customizing your RoundUp page is required to
							activate your custom RoundUp App URLs.
						</strong>
					</p>
					<div
						style="display: flex; flex-direction: row; justify-content: center"
					>
						<div
							style="background-color: #F4F6FC; padding: 40px 80px 40px 80px; max-width: 484px"
						>
							<p style="margin-bottom: 30px !important;">
								<strong>Activate Your Custom URLs Now!</strong>
							</p>
							<div
								style="display: flex; flex-direction: row; margin-bottom: 30px !important"
							>
								<v-icon size="32" color="#484A50">
									fa-regular fa-image
								</v-icon>
								<span style="margin-left: 24px">
									Add your logo
								</span>
							</div>
							<div
								style="display: flex; flex-direction: row; margin-bottom: 30px !important"
							>
								<v-icon size="32" color="#484A50">
									fa-regular fa-images
								</v-icon>
								<span style="margin-left: 24px">
									Add a cover image
								</span>
							</div>
							<div
								style="display: flex; flex-direction: row; margin-bottom: 30px !important"
							>
								<v-icon size="32" color="#484A50">
									fa-regular fa-bullhorn
								</v-icon>
								<span style="margin-left: 24px">
									Add your mission statement
								</span>
							</div>
							<div
								style="display: flex; flex-direction: row; justify-content: center;"
							>
								<RoundupButton
									class="primary"
									style="width: 100%"
									@click="onClick"
									id="get-started-custom-page"
								>
									Get Started
								</RoundupButton>
							</div>
						</div>
					</div>
				</div>
				<div
					class="body-right pl-10"
					style="flex: 3; position: relative"
				>
					<v-img
						max-width="336px"
						src="@/assets/brand_onboarding_pro_tip.png"
					/>
					<p
						style="position: absolute; top: 20px; left: 60px; color: white"
					>
						<strong>PRO TIP</strong>
					</p>
					<v-divider
						style="width: 100%; max-width: 296px; border-color: white; position: absolute; top: 55px; left: 60px; color: white"
					/>
					<div
						class="pr-7"
						style="position: absolute; top: 75px; left: 60px;"
					>
						<p style="color: white; max-width: 316px">
							<strong>Adding</strong> your custom URLs to your
							website <strong>and sharing</strong> on social media
							<strong>can increase</strong> the number of
							<strong>donations</strong>
							given through RoundUp App
							<strong>by 33% or more!</strong>
						</p>
					</div>
				</div>
			</div>
		</template>
	</screen>
</template>

<script>
import Screen from '../../ui/Screen'

export default {
	name: 'brand-onboarding',
	components: {
		Screen
	},
	methods: {
		onClick() {
			window.localStorage.setItem('startedBrandOnboarding', true)
			this.$emit('showBrandView', true)
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.brand-onboarding {
	position: relative;
}
h2 {
	text-align: center;
}
</style>
