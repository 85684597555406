<template>
	<div>
		<brand-onboarding
			v-if="showBrandOnboarding"
			@showBrandView="onShowBrandView"
		/>

		<div
			class="screen-wrapper deep-register-view"
			style="margin-bottom: 0px"
			v-else
		>
			<div
				class="alert alert-info"
				:style="{ maxWidth: '1280px', textAlign: 'center' }"
				v-if="brandComplete"
			>
				<h3>Great Job! Step 2 Complete</h3>
				<router-link :to="{ name: 'home' }"
					><ui-button>Return to Overview</ui-button></router-link
				>
			</div>

			<v-container fluid class="pa-3">
				<div class="brand-view">
					<!-- 									*** HEADER ***								-->
					<div
						class="brand-view-header"
						style="padding: 12px 0px 0px 16px"
					>
						<h1
							slot="title"
							style="
								margin-bottom: 0px;
								line-height: 1;
								text-align: left !important;
							"
						>
							Brand Profile
						</h1>
						<p
							style="
								font-size: 16px;
								margin-bottom: 16px !important;
								margin-top: 24px !important;
							"
						>
							Attract more donors by promoting RoundUp App with
							custom URLs! Share the custom URL on your website
							and social media channels to link donors directly to
							your organization on RoundUp App and increase the
							number of donations by up to 33%!
						</p>
						<div
							class="brand-view-header-action"
							style="display: flex; flex-direction: row"
						>
							<div class="action-left" style="flex: 3">
								<div
									style="
										margin-bottom: 10px;
										display: flex;
										flex-direction: row;
										justify-content: space-between;
										align-items: center;
									"
								>
									<p
										style="
											margin-top: 6px !important;
											line-height: 1;
											font-weight: 600;
											font-size: 14px;
										"
									>
										Custom URLS
										{{ showCustomURL ? '' : '(INACTIVE)' }}
									</p>
									<div>
										<v-icon
											size="20px"
											v-if="copied"
											color="green"
											>fa-solid fa-check</v-icon
										>
										<v-btn
											v-else
											:disabled="!showCustomURL"
											icon
											@click="copyToClipboard()"
											class="url-button"
										>
											<v-icon size="20px" color="#484A50"
												>far fa-copy</v-icon
											>
										</v-btn>
										<v-btn
											:disabled="!showCustomURL"
											icon
											@click="editCustomURLModal = true"
											class="ml-5 url-button"
										>
											<v-icon size="20px" color="#484A50"
												>far fa-edit</v-icon
											>
										</v-btn>
									</div>
								</div>
								<div style="display: flex; flex-direction: row">
									<div class="pr-5">
										<v-select
											dense
											:items="customURLTypes"
											v-model="activeCustomURLType"
											outlined
											label="URL TYPE"
											style="max-width: 200px"
										/>
									</div>
									<v-text-field
										class="custom-url-input"
										:disabled="!showCustomURL"
										dense
										outlined
										:value="deeplink"
										readonly
									></v-text-field>
								</div>
								<div>
									<v-alert
										color="#E9EDFA"
										icon="fas fa-info-circle"
										info
										><span style="color: #4133a8">
											{{
												activeCustomURLType ===
												'Sign Up'
													? `Use the Sign Up URL to invite donors to
										sign up on RoundUp App to give recurring
										roundup or monthly donations.`
													: `Use the One Time URL to invite giving for special campaigns, events, or other one time gifts without having to sign up.`
											}}
										</span>
									</v-alert>
								</div>
							</div>
							<div class="action-right" style="flex: 2">
								<v-spacer style="height: 46px" />
								<div
									style="
										display: flex;
										flex-direction: column;
									"
									class="pl-5"
								>
									<Roundup-button
										@click="goToCustomPage"
										:disabled="!showCustomURL"
										class="outlined action-right-button"
										style="
											margin-bottom: 8px !important;
											max-width: 185px !important;
											color: #2553cd !important;
											border: 1px solid #2553cd !important;
										"
									>
										View Page
									</Roundup-button>
									<!-- <Roundup-button
									@click="generateQRCode"
									:disabled="!showCustomURL || showQRCode"
									class="outlined action-right-button"
									style="max-width: 185px !important; color:#2553CD !important; border: 1px solid #2553CD !important"
								>
									{{
										showQRCode
											? 'Coming Soon!'
											: 'Generate QR Code'
									}}
								</Roundup-button> -->
								</div>
							</div>
						</div>
					</div>
					<v-divider class="mt-2 mb-4" />
					<div class="deep-register-parts">
						<!-- 									*** CHECKLIST ***								-->

						<div
							:style="{
								justifyContent: dismissTutorial
									? 'center'
									: 'flex-start'
							}"
							style="
								display: flex;
								flex-direction: row;
								width: 100%;
							"
						>
							<v-card
								v-if="!dismissTutorial"
								class="mr-10"
								style="
									margin-bottom: auto;
									border-radius: 4px !important;
									max-width: 400px !important;
								"
							>
								<v-card-title
									style="
										background-color: #4337a7;
										border-top-left-radius: 4px !important;
										border-top-right-radius: 4px !important;
									"
									><h3 style="color: white !important">
										Activate Custom URLs
									</h3>
								</v-card-title>
								<v-card-text class="px-5">
									<v-row style="margin-top: 25px !important">
										<v-row>
											<v-icon
												:style="{
													color: hasLogo
														? '#3DF4CB'
														: '#D4D4D4'
												}"
												>{{
													hasLogo
														? 'fas fa-check-circle'
														: 'far fa-circle'
												}}</v-icon
											>
											<h3
												style="
													margin-left: 10px;
													font-size: 20px !important;
												"
											>
												Add logo image
											</h3>
										</v-row>

										<p
											style="
												margin-top: 4px !important;
												padding-left: 34px;
											"
										>
											Recommended size is 150x150 (1:1).
											Upload from your computer or connect
											an image from Facebook or another
											URL.
										</p>
									</v-row>
									<v-row style="margin-top: 25px !important">
										<v-row>
											<v-icon
												:style="{
													color: hasCoverImage
														? '#3DF4CB'
														: '#D4D4D4'
												}"
												>{{
													hasCoverImage
														? 'fas fa-check-circle'
														: 'far fa-circle'
												}}</v-icon
											>
											<h3
												style="
													margin-left: 10px;
													font-size: 20px !important;
												"
											>
												Add cover image
											</h3>
										</v-row>
										<p
											style="
												margin-top: 4px !important;
												padding-left: 34px;
											"
										>
											Recommended size is 1280x720 (16:9).
											Upload from your computer or connect
											an image from Facebook or another
											URL.
										</p>
									</v-row>
									<v-row
										style="
											margin-top: 25px !important;
											margin-bottom: 25px !important;
										"
									>
										<v-row>
											<v-icon
												:style="{
													color: !hasOriginalMissionStatement
														? '#3DF4CB'
														: '#D4D4D4'
												}"
												>{{
													!hasOriginalMissionStatement
														? 'fas fa-check-circle'
														: 'far fa-circle'
												}}</v-icon
											>
											<h3
												style="
													margin-left: 10px;
													font-size: 20px !important;
												"
											>
												Add Mission Statement
											</h3>
										</v-row>

										<p
											style="
												margin-top: 4px !important;
												padding-left: 34px;
											"
										>
											Tell your story! Include 3-5
											sentences about your mission and the
											good you are doing. Let donors know
											how their contributions make a real
											impact.
										</p>
									</v-row>
								</v-card-text>
							</v-card>

							<!-- 		*** EDITABLE BRAND DETAILS ***			-->

							<v-card style="max-width: 650px !important">
								<!-- COVER IMAGE W/ IMAGE -->
								<div class="cover">
									<v-img
										v-if="hasCoverImage"
										@error="downgradeImage"
										:src="institution.image_url"
										max-height="360px"
										style="box-shadow: none"
									>
										<div class="has-upload-cover">
											<v-btn
												:loading="isCoverLoading"
												@click="triggerCoverUpload"
												style="
													letter-spacing: normal !important;
													border: 1px solid #747a8d;
													text-transform: inherit !important;
													font-size: 14px !important;
													padding-left: 10px !important;
													padding-right: 10px !important;
													background: white !important;
												"
											>
												<v-icon size="18px" class="mr-1"
													>fas fa-camera</v-icon
												>
												{{
													!isCoverLoading &&
														'Update Cover Photo'
												}}
											</v-btn>
										</div>
									</v-img>
									<!-- COVER IMAGE WITHOUT IMAGE -->

									<v-img
										v-else
										style="
											width: 100%;
											height: 360px;
											background: #f5f6f8;
											justify-content: center;
											align-items: center;
										"
									>
										<div class="upload-cover">
											<v-btn
												style="
													letter-spacing: normal !important;
													box-shadow: none !important;
													border: 1px solid #747a8d;
													text-transform: none !important;
													font-size: 14px !important;
													padding-left: 10px !important;
													padding-right: 10px !important;
													background: white !important;
												"
												:loading="isCoverLoading"
												@click="triggerCoverUpload"
											>
												<v-icon size="18px" class="mr-1"
													>fas fa-camera</v-icon
												>
												{{
													!isCoverLoading &&
														'Add Cover Photo'
												}}
											</v-btn>
										</div>
									</v-img>
									<!-- PROFILE LOGO W/ LOGO -->

									<div class="profile-icon">
										<v-avatar
											size="150"
											tile
											style="
												border-radius: 7px !important;
												background-color: #dcdee3;
											"
										>
											<img
												v-if="hasLogo"
												class="logo"
												:src="institution.logo_url"
												alt="org_logo"
											/>
											<!-- PROFILE LOGO WITHOUT LOGO -->

											<v-img
												v-else
												style="
													width: 100%;
													background: #dcdee3;
													justify-content: center;
													align-items: center;
													display: flex;
												"
											>
												<v-btn
													:loading="isLoading"
													@click="triggerLogoUpload"
													style="
														background: white !important;
														box-shadow: none;
														margin: auto;
														border: 1px solid
															#747a8d;
														text-transform: inherit !important;
														font-size: 14px !important;
														padding-left: 10px !important;
														padding-right: 10px !important;
													"
												>
													<v-btn
														style="margin: auto"
														small
														v-if="!isLogoLoading"
														><v-icon
															>fas
															fa-camera</v-icon
														>
														Add Logo
													</v-btn>
												</v-btn>
											</v-img>
										</v-avatar>
										<v-btn
											v-if="hasLogo"
											fab
											small
											style="
												box-shadow: none;
												background: white;
											"
											class="camera-icon"
											:loading="isLogoLoading"
											@click="triggerLogoUpload"
										>
											<v-icon small v-if="!isLogoLoading">
												fas fa-camera
											</v-icon>
										</v-btn>
									</div>
								</div>
								<div class="brand-card-body">
									<!-- TITLE SECTION -->

									<div
										class="brand-card-body-section"
										v-if="!editableBrandSections.title.show"
									>
										<div
											class="brand-card-body-row"
											style="
												position: relative;
												align-items: flex-start;
												justify-content: flex-end;
											"
										>
											<h3 class="brand-card-body-title">
												{{ institution.name }}
											</h3>
											<v-btn
												icon
												class="edit-icon mt-2"
												@click="
													editableBrandSections.title.show = true
												"
											>
												<v-icon size="20px"
													>far fa-edit</v-icon
												>
											</v-btn>
										</div>
										<div
											style="
												letter-spacing: 0.29px;
												width: auto;
												margin: 10px auto 0px auto;
												justify-content: center;
											"
											class="brand-card-body-row"
											v-if="cityAndState"
										>
											<span class="mr-4">{{
												cityAndState
											}}</span>
											<span>|</span>
											<span
												class="ml-4"
												v-if="
													institution &&
														institution.ein
												"
												>EIN:
												{{ institution.ein }}</span
											>
										</div>
									</div>
									<!-- EDITABLE TITLE SECTION -->

									<div class="brand-card-body-section" v-else>
										<v-form
											v-model="
												editableBrandSections.mission
													.formValid
											"
											lazy-validation
											v-on:submit.prevent="
												saveBrandEdits('title')
											"
										>
											<v-text-field
												v-model="
													editableBrandSections.title
														.name
												"
												label="Nonprofit Name"
											/>
											<div
												class="mt-4"
												style="
													display: flex;
													flex-direction: row;
													justify-content: flex-end;
												"
											>
												<RoundupButton
													class="clear clear-border mr-2"
													@click="
														editableBrandSections.title.show = false
													"
												>
													Cancel
												</RoundupButton>
												<RoundupButton
													:loading="isLoading"
													class="primary"
													type="submit"
												>
													Save
												</RoundupButton>
											</div>
										</v-form>
									</div>
									<v-divider style="width: 94%" />
									<!-- MISSION SECTION -->

									<div
										v-if="
											!editableBrandSections.mission.show
										"
										class="brand-card-body-section"
									>
										<div
											style="
												justify-content: space-between;
											"
											class="brand-card-body-row"
										>
											<span
												class="brand-card-body-row-title"
											>
												Mission
											</span>
											<v-btn
												icon
												class="edit-icon"
												@click="
													editableBrandSections.mission.show = true
												"
											>
												<v-icon size="20px"
													>far fa-edit</v-icon
												>
											</v-btn>
										</div>
										<div
											v-show="fullImpactStatement"
											style="
												justify-content: space-between;
											"
											class="brand-card-body-row mt-3 mb-4"
										>
											<span
												style="
													font-size: 20px;
													font-weight: 600;
												"
												>Your donation allows us to
												{{ fullImpactStatement }}</span
											>
										</div>

										<div
											class="brand-card-body-row dashed-box mb-6"
											@click="
												editableBrandSections.mission.show = true
											"
											v-if="hasOriginalDescription"
										>
											Click to add Mission Statement
										</div>
										<div
											class="brand-card-body-row mb-6 brand-card-body-description"
											v-else
										>
											<span
												>{{
													!editableBrandSections
														.mission
														.showFullDescription
														? truncatedDescription
														: editableBrandSections
																.mission
																.showFullDescription
														? institution.description +
														  ' '
														: null
												}}<span
													style="
														color: #27282b !important;
														font-weight: 600;
														cursor: pointer;
														text-decoration: underline;
													"
													v-show="
														institution.description
															.length >= 400
													"
													@click="
														editableBrandSections.mission.showFullDescription = !editableBrandSections
															.mission
															.showFullDescription
													"
													>{{
														editableBrandSections
															.mission
															.showFullDescription
															? 'read less'
															: 'read more'
													}}</span
												></span
											>
										</div>
										<div
											class="brand-card-body-row mb-5"
											v-if="category"
										>
											<v-icon
												size="18px"
												class="brand-card-body-icon"
												>fa-solid fa-flag</v-icon
											><span>{{ category }}</span>
										</div>
										<div
											class="brand-card-body-row"
											v-if="subcategory"
										>
											<v-icon
												size="18px"
												class="brand-card-body-icon"
												>fa-solid fa-bullseye</v-icon
											><span>{{ subcategory }}</span>
										</div>
									</div>
									<!-- EDITABLE MISSION SECTION -->

									<div v-else class="brand-card-body-section">
										<div
											style="
												justify-content: flex-start;
												height: 36px;
											"
											class="brand-card-body-row"
										>
											<span
												style="
													font-size: 20px;
													font-weight: 600;
												"
												>Your donation allows us to
												{{ fullImpactStatement }}
											</span>
										</div>
										<v-form
											lazy-validation
											v-model="
												editableBrandSections.mission
													.formValid
											"
											v-on:submit.prevent="
												saveBrandEdits('mission')
											"
										>
											<v-text-field
												label="Impact Statement"
												v-model="
													editableBrandSections
														.mission
														.fullImpactStatement
												"
												:rules="[
													v =>
														(v &&
															v.length <= 100) ||
														'Your impact statement cannot be larger than 100 characters.'
												]"
												counter="100"
												class="mb-8 mt-10"
											/>
											<v-textarea
												outlined
												label="Mission Statement"
												v-model="
													editableBrandSections
														.mission.description
												"
												:placeholder="
													hasOriginalDescription
														? institution.description
														: missionStatementDefault
												"
												:counter="true"
												:rules="[
													v =>
														v.length >= 20 ||
														'Your description must be at least 20 characters'
												]"
											/>
											<div
												class="brand-card-body-row"
												v-if="category"
											>
												<v-icon
													size="18px"
													class="brand-card-body-icon mb-5"
													>fa-solid fa-flag</v-icon
												><span style="width: 100%">
													<v-select
														dense
														outlined
														:items="
															categories.categories
														"
														v-model="
															editableBrandSections
																.mission
																.categoryCode
														"
														@change="
															onCategoryChange
														"
														item-text="title"
														item-value="ntee"
														label="Category"
												/></span>
											</div>
											<div
												class="brand-card-body-row"
												v-if="subcategory"
											>
												<v-icon
													size="18px"
													class="brand-card-body-icon mb-5"
													>fa-solid
													fa-bullseye</v-icon
												><span style="width: 100%">
													<v-select
														dense
														outlined
														:items="
															filteredSubcategories
														"
														v-model="
															editableBrandSections
																.mission
																.nteeCode
														"
														:rules="[
															v => {
																if (!v) {
																	return 'Please select a subcategory'
																}
																return true
															}
														]"
														item-text="title"
														item-value="ntee"
														label="Subcategory"
												/></span>
											</div>
											<div
												class="mt-4"
												style="
													display: flex;
													flex-direction: row;
													justify-content: flex-end;
												"
											>
												<RoundupButton
													class="clear clear-border mr-2"
													@click="
														closeEditableBrandSections
													"
												>
													Cancel
												</RoundupButton>
												<RoundupButton
													:disabled="
														!editableBrandSections
															.mission.formValid
													"
													:loading="isLoading"
													class="primary"
													type="submit"
												>
													Save
												</RoundupButton>
											</div>
										</v-form>
									</div>
									<!-- SOCIAL SECTION -->

									<div
										class="brand-card-body-section"
										style="background-color: #f4f6fc"
										v-if="
											!editableBrandSections.social.show
										"
									>
										<div class="brand-card-body-row mb-5">
											<div
												style="
													justify-content: space-between;
												"
												class="brand-card-body-row"
											>
												<span
													class="brand-card-body-row-title"
												>
													Social
												</span>
												<v-btn
													icon
													class="edit-icon"
													@click="
														editableBrandSections.social.show = true
													"
												>
													<v-icon size="20px"
														>far fa-edit</v-icon
													>
												</v-btn>
											</div>
										</div>
										<div
											class="brand-card-body-row mb-4"
											v-if="websiteURL"
										>
											<v-icon
												size="18px"
												class="brand-card-body-icon"
												>fa-solid fa-link</v-icon
											><a
												target="_blank"
												style="text-decoration: none"
												:href="withHttp(websiteURL)"
												>{{ websiteURL }}</a
											>
										</div>
										<div
											class="brand-card-body-row dashed-box"
											@click="
												editableBrandSections.social.show = true
											"
											v-if="!hasSocialMedia"
										>
											<div class="mb-3">
												Click to add social media links
											</div>
											<div
												style="
													display: flex;
													flex-direction: row;
												"
											>
												<v-icon size="18px"
													>fab fa-facebook</v-icon
												>
												<v-icon size="18px" class="mx-6"
													>fab fa-instagram</v-icon
												>
												<v-icon>fab fa-twitter</v-icon>
											</div>
										</div>
										<div v-else>
											<div
												class="brand-card-body-row mb-4"
											>
												<v-icon
													size="18px"
													class="brand-card-body-icon"
													>fab fa-facebook</v-icon
												><a
													target="_blank"
													v-if="
														institution.facebook_url
													"
													style="
														text-decoration: none;
													"
													:href="
														withHttp(
															institution.facebook_url
														)
													"
													>{{
														institution.facebook_url
													}}</a
												>
												<span v-else> N/A </span>
											</div>
											<div
												class="brand-card-body-row mb-4"
											>
												<v-icon
													size="18px"
													class="brand-card-body-icon"
													>fab fa-twitter</v-icon
												><a
													target="_blank"
													style="
														text-decoration: none;
													"
													v-if="
														institution.twitter_url
													"
													:href="
														withHttp(
															institution.twitter_url
														)
													"
													>{{
														institution.twitter_url
													}}</a
												>
												<span v-else> N/A </span>
											</div>
											<div
												class="brand-card-body-row mb-4"
											>
												<v-icon
													size="18px"
													class="brand-card-body-icon"
													>fab fa-instagram</v-icon
												><a
													target="_blank"
													v-if="
														institution.instagram_url
													"
													style="
														text-decoration: none;
													"
													:href="
														withHttp(
															institution.instagram_url
														)
													"
													>{{
														institution.instagram_url
													}}</a
												>
												<span v-else> N/A </span>
											</div>
										</div>
									</div>
									<div
										v-else
										style="background-color: #f4f6fc"
										class="brand-card-body-section"
									>
										<div class="brand-card-body-row mb-5">
											<div
												style="
													justify-content: flex-start;
													height: 36px;
												"
												class="brand-card-body-row"
											>
												<span
													class="brand-card-body-row-title"
												>
													Social
												</span>
											</div>
										</div>
										<div class="brand-card-body-row">
											<v-form
												lazy-validation
												v-model="
													editableBrandSections.social
														.formValid
												"
												v-on:submit.prevent="
													saveBrandEdits('social')
												"
												style="width: 100%"
											>
												<div
													class="brand-card-body-row"
												>
													<v-icon
														size="18px"
														class="brand-card-body-icon"
														>fa-solid
														fa-link</v-icon
													>
													<span style="width: 100%">
														<v-text-field
															v-model="
																editableBrandSections
																	.social
																	.websiteURL
															"
															label="Website"
															:rules="[
																socialURLRules
															]"
														/>
													</span>
												</div>
												<div
													class="brand-card-body-row"
												>
													<v-icon
														size="18px"
														class="brand-card-body-icon"
														>fab fa-facebook</v-icon
													>
													<span style="width: 100%">
														<v-text-field
															v-model="
																editableBrandSections
																	.social
																	.facebookURL
															"
															label="Facebook"
															:rules="[
																socialURLRules
															]"
														/>
													</span>
												</div>
												<div
													class="brand-card-body-row"
												>
													<v-icon
														size="18px"
														class="brand-card-body-icon"
														>fab fa-twitter</v-icon
													>
													<span style="width: 100%">
														<v-text-field
															v-model="
																editableBrandSections
																	.social
																	.twitterURL
															"
															label="Twitter"
															:rules="[
																socialURLRules
															]"
														/>
													</span>
												</div>
												<div
													class="brand-card-body-row"
												>
													<v-icon
														size="18px"
														class="brand-card-body-icon"
														>fab
														fa-instagram</v-icon
													>
													<span style="width: 100%">
														<v-text-field
															v-model="
																editableBrandSections
																	.social
																	.instagramURL
															"
															label="Instagram"
															:rules="[
																socialURLRules
															]"
														/>
													</span>
												</div>
												<div
													class="brand-card-body-row mt-4"
													style="
														justify-content: flex-end;
													"
												>
													<RoundupButton
														class="clear clear-border mr-2"
														@click="
															closeEditableBrandSections
														"
													>
														Cancel
													</RoundupButton>
													<RoundupButton
														:disabled="
															!editableBrandSections
																.social
																.formValid
														"
														:loading="isLoading"
														class="primary"
														type="submit"
													>
														Save
													</RoundupButton>
												</div>
											</v-form>
										</div>
									</div>
									<!-- CONTACT SECTION -->
									<div
										v-if="
											!editableBrandSections.contact.show
										"
										class="brand-card-body-section"
										style="min-height: 285px"
									>
										<div class="brand-card-body-row mb-5">
											<div
												style="
													justify-content: space-between;
												"
												class="brand-card-body-row"
											>
												<span
													class="brand-card-body-row-title"
												>
													Contact
												</span>
												<v-btn
													icon
													class="edit-icon"
													@click="
														editableBrandSections.contact.show = true
													"
												>
													<v-icon size="20px"
														>far fa-edit</v-icon
													>
												</v-btn>
											</div>
										</div>
										<div
											class="brand-card-body-row mb-4"
											v-if="address"
										>
											<v-icon
												size="18px"
												class="brand-card-body-icon"
												>fas fa-map-marker-alt</v-icon
											><span>{{ address }}</span>
										</div>
										<div v-if="hasConctactDetails">
											<div
												class="brand-card-body-row mb-4"
											>
												<v-icon
													:size="18"
													class="brand-card-body-icon"
													>fas fa-user</v-icon
												><span>{{
													institution.contact_name
														? institution.contact_name
														: 'N/A'
												}}</span>
											</div>
											<div class="brand-card-body-row">
												<v-icon
													:size="18"
													class="brand-card-body-icon"
													>fas fa-envelope</v-icon
												><span>{{
													institution.contact_email
														? institution.contact_email
														: 'N/A'
												}}</span>
											</div>
										</div>
										<div
											v-else
											class="brand-card-body-row dashed-box"
											@click="
												editableBrandSections.contact.show = true
											"
										>
											<div class="mb-3">
												Click to enter optional contact
												details
											</div>
											<div
												style="
													display: flex;
													flex-direction: row;
												"
											>
												<v-icon class="mr-6" size="18px"
													>fas fa-envelope</v-icon
												>
												<v-icon size="18px"
													>fas fa-user</v-icon
												>
											</div>
										</div>
									</div>
									<div
										v-else
										class="brand-card-body-section"
										style="min-height: 285px"
									>
										<div class="brand-card-body-row mb-5">
											<div
												style="
													justify-content: flex-start;
													height: 36px;
												"
												class="brand-card-body-row"
											>
												<span
													class="brand-card-body-row-title"
												>
													Contact
												</span>
											</div>
										</div>
										<div class="brand-card-body-row">
											<v-icon
												size="18px"
												class="mr-4 brand-card-body-icon"
											>
												fa-s fa-map-marker-alt</v-icon
											>
											<LocationInput
												style="width: 100%"
												ref="location"
												id="map"
												:initLocation="formattedAddress"
												placeholder=""
												v-on:placechanged="
													getAddressData
												"
												country="us"
												autocomplete="false"
												class="roundup-input"
												@focus="onLocationInputFocus"
												label="Address"
											>
											</LocationInput>
										</div>
										<div class="brand-card-body-row">
											<v-icon
												size="18px"
												class="mr-4 brand-card-body-icon"
											>
												fas fa-user</v-icon
											><span style="width: 100%">
												<v-text-field
													label="Contact Name"
													v-model="
														editableBrandSections
															.contact.name
													"
												/>
											</span>
										</div>
										<div class="brand-card-body-row mb-5">
											<v-icon
												size="18px"
												class="mr-4 brand-card-body-icon"
												>fas fa-envelope</v-icon
											>
											<span style="width: 100%">
												<v-text-field
													label="Contact Email"
													v-model="
														editableBrandSections
															.contact.email
													"
												/>
											</span>
										</div>

										<div
											class="brand-card-body-row"
											style="justify-content: flex-end"
										>
											<RoundupButton
												class="clear clear-border mr-2"
												@click="
													() => {
														institution.address.google = true
														showLocationDetails = false
														closeEditableBrandSections()
													}
												"
											>
												Cancel
											</RoundupButton>
											<RoundupButton
												:loading="isLoading"
												class="primary"
												@click="
													saveBrandEdits('contact')
												"
											>
												Save
											</RoundupButton>
										</div>
									</div>
								</div>
							</v-card>
						</div>
					</div>
					<v-dialog max-width="760px" v-model="showEditModal">
						<v-card>
							<v-card-title>{{ editValue.label }}</v-card-title>
							<v-card-text>
								<p v-if="editValue.value == 'impact_statement'">
									Fill in this sentence with a statement of
									the impact donors will be making: "<strong
										>{{ impact_statement }}</strong
									>
									Just by Donating Your Change." (Example:
									"Feed Hungry Children," or "Support Urban
									Justice.")
								</p>
								<p
									v-if="
										editValue.value ==
											'full_impact_statement'
									"
								>
									Fill in this sentence with a statement of
									the impact donors will be making: "Donating
									the spare change from your credit or debit
									card purchases allows us to
									<strong>{{ full_impact_statement }}</strong
									>." (Example: "provide lifesaving food for
									children and their families")
								</p>

								<p v-else>{{ editValue.subtitle }}</p>
								<form v-on:submit.prevent="update">
									<ui-label>
										<ui-input
											v-model="
												institution[editValue.value]
											"
											placeholder="Enter your statement here"
											:maxlength="editValue.max"
										/>
									</ui-label>
									<v-row>
										<Roundup-Button
											color="white"
											style="margin-top: 25px !important"
											@click="showEditModal = false"
											>Close</Roundup-Button
										>
										<v-spacer></v-spacer>
										<Roundup-Button
											color="primary"
											type="submit"
											style="margin-top: 25px !important"
											:loading="isLoading"
											>Save</Roundup-Button
										>
									</v-row>
								</form>
							</v-card-text>
						</v-card>
					</v-dialog>
					<modal v-model="showTutorialModal">
						<h3>Welcome to your Custom Registration Page</h3>
						<p>
							We are going to go through a quick tutorial to make
							sure everything gets completed.
						</p>
						<p :style="{ fontSize: '14px' }">
							You can exit at anytime by pressing the "X" in the
							top right. If you do, make this page your own by
							editing any text that has a
							<img
								src="@/assets/edit.png"
								:style="{
									height: '20px',
									width: 'auto',
									margin: 'auto 5px'
								}"
							/>
							next to it. Just click on the text to edit.
						</p>
						<ui-button @click="nextTutorial">Next</ui-button>
					</modal>
					<v-dialog
						v-model="showInfoModal"
						size="medium"
						max-width="720px"
					>
						<v-card style="padding: 25px">
							<v-card-title>
								Custom Registration Page
							</v-card-title>
							<v-card-text>
								<p>
									Convert more donors by promoting RoundUp App
									with your custom registration page.
								</p>
								<p style="margin-top: 24px !important">
									<strong>Logo image</strong> should be square
									(1:1 ratio) with dimensions of at least
									150px x 150px. File size must be less than 1
									MB.
								</p>
								<p style="margin-top: 24px !important">
									<strong>Cover photo</strong> should have a
									16:9 ratio. We recommend 1280px x 720px.
									File size must be less than 1 MB.
								</p>
								<p style="margin-top: 24px !important">
									<strong>Impact statements</strong> can be
									customized to better match your specific
									cause. Simply click the edit icon and follow
									the prompts.
								</p>
							</v-card-text>
							<v-card-actions>
								<Roundup-button
									color="primary"
									@click="showInfoModal = false"
									>Close</Roundup-button
								>
							</v-card-actions>
						</v-card>
					</v-dialog>
					<v-dialog v-model="editCustomURLModal" max-width="480px">
						<v-card>
							<v-app-bar flat color="white" class="pl-2">
								<v-toolbar-title>
									Update Custom URLs
								</v-toolbar-title>
								<v-spacer />
								<v-btn
									small
									fab
									elevation="0"
									plain
									@click="editCustomURLModal = false"
									><v-icon color="black"
										>fas fa-times</v-icon
									></v-btn
								>
							</v-app-bar>
							<v-card-text>
								<v-text-field
									v-model="newInstitutionUsername"
									label="Custom URL Name"
								/>
								<div class="mt-1">
									<p style="font-weight: 600; color: #484a50">
										Sign Up URL
									</p>
									<a
										style="text-decoration: none"
										@click="openCustomURLWindow('p')"
									>
										{{
											`roundup.app/p/${newInstitutionUsername}`
										}}</a
									>
								</div>
								<div class="mt-6">
									<p style="font-weight: 600; color: #484a50">
										One Time URL
									</p>
									<a
										style="text-decoration: none"
										@click="openCustomURLWindow('d')"
									>
										{{
											`roundup.app/d/${newInstitutionUsername}`
										}}</a
									>
								</div>
							</v-card-text>
							<v-card-actions class="pb-5">
								<v-spacer />
								<RoundupButton
									class="outlined"
									style="border: 1px solid #2553cd !important"
									@click="
										() => {
											newInstitutionUsername =
												institution.username
											editCustomURLModal = false
										}
									"
									>Cancel</RoundupButton
								>
								<RoundupButton
									@click="onCustomURLModalSave"
									class="primary"
									>Save</RoundupButton
								>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</div>
			</v-container>
			<div class="overlay" v-if="currentTutorialStep > -1">
				<div
					:style="{
						position: 'absolute',
						top: '10px',
						right: '20px'
					}"
				>
					<ui-button @click="closeTutorial" type="white"
						>Close Tutorial</ui-button
					>
				</div>
			</div>
			<v-dialog v-model="showLocationDetails" width="500">
				<v-card>
					<v-card-title> Please Confirm Your Address </v-card-title>

					<v-card-text>
						<v-form ref="confirmForm">
							<v-row>
								<v-text-field
									v-model="
										editableBrandSections.contact
											.street_number
									"
									label="Street Number"
									required
									:rules="[
										() =>
											!!editableBrandSections.contact
												.street_number ||
											'This field is required'
									]"
								></v-text-field>
								<v-text-field
									v-model="
										editableBrandSections.contact
											.street_first
									"
									label="Street Name"
									required
									:rules="[
										() =>
											!!editableBrandSections.contact
												.street_first ||
											'This field is required'
									]"
								></v-text-field>
							</v-row>
							<v-row>
								<v-text-field
									v-model="editableBrandSections.contact.city"
									label="City"
									:rules="[
										() =>
											!!editableBrandSections.contact
												.city ||
											'This field is required'
									]"
									required
								></v-text-field>
								<v-spacer></v-spacer>
								<v-select
									:items="usStates"
									placeholder="Texas"
									item-text="name"
									item-value="code"
									label="State"
									:rules="[
										() =>
											!!editableBrandSections.contact
												.state ||
											'This field is required'
									]"
									v-model="
										editableBrandSections.contact.state
									"
									style="max-width: 212px !important"
								>
								</v-select>
							</v-row>
							<v-row>
								<v-text-field
									style="max-width: 212px !important"
									v-model="editableBrandSections.contact.zip"
									label="Zip Code"
									counter="5"
									required
									:rules="[
										() =>
											!!editableBrandSections.contact
												.zip ||
											'This field is required',
										v =>
											v && v.length !== 5
												? 'Enter a valid zip code'
												: true
									]"
									type="number"
									maxlength="5"
								></v-text-field>
								<v-spacer></v-spacer>
							</v-row>
						</v-form>
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<Roundup-Button
							color="primary"
							text
							@click="showLocationDetails = false"
						>
							Close
						</Roundup-Button>
						<v-spacer></v-spacer>
						<Roundup-Button color="primary" @click="confirmAddress">
							Confirm
						</Roundup-Button>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { get, withHttp } from '@/utilities'
import * as filestack from 'filestack-js'

import Screen from '../../ui/Screen'
import UiLabel from '../../ui/Label'
import UiInput from '../../ui/Input'
import UiButton from '../../ui/Button'
import Modal from '../../ui/Modal'
import BrandOnboarding from '../Onboarding/BrandOnboarding.vue'
import { analytics } from '@/utilities/tracker'
import { LocationInput } from '../../ui'
import { states } from '@/utilities/enums'

export default {
	name: 'profile-view',
	components: {
		Screen,
		UiInput,
		UiLabel,
		UiButton,
		Modal,
		BrandOnboarding,
		LocationInput
	},
	data() {
		return {
			showSuccess: false,
			institution: cloneDeep(this.$store.state.institution.item),
			isLoading: false,
			isCoverLoading: false,
			isLogoLoading: false,
			showEditModal: false,
			editValue: {},
			showTutorialModal: false,
			currentTutorialStep: -1,
			brandComplete: false,
			editImpact: false,
			editSignupCTA: false,
			editFullImpact: false,
			showInfoModal: false,
			dismissTutorial: false,
			showBrandView: false,
			customURLTypes: ['Sign Up', 'One Time'],
			activeCustomURLType: 'Sign Up',
			linkEditModeActive: false,
			copied: false,
			showQRCode: false,
			editCustomURLModal: false,
			newInstitutionUsername: null,
			successModal: false,
			isBrandEditLoading: false,
			formValid: true,
			showLocationDetails: false,
			missionStatementDefault:
				"Add 3 - 5 sentences (or more if you'd like) describing your mission",
			editableBrandSections: {
				title: {
					formValid: true,
					show: false,
					name: null
				},
				mission: {
					formValid: true,
					show: false,
					fullImpactStatement: null,
					showFullDescription: false,
					description: null,
					categoryCode: null,
					nteeCode: null
				},
				social: {
					formValid: true,
					show: false,
					facebookURL: null,
					twitterURL: null,
					instagramURL: null,
					websiteURL: null
				},
				contact: {
					formValid: true,
					show: false,
					street_first: null,
					city: null,
					state: null,
					zip: null,
					google: true,
					email: null,
					name: null
				}
			}
		}
	},
	computed: {
		...mapGetters([
			'hasImages',
			'hasLogo',
			'hasCoverImage',
			'onboardingStep',
			'hasBrand',
			'me',
			'categories'
		]),
		category() {
			if (this.institution && this.institution.ntee) {
				return this.institution.ntee.category.category_title
			}
			const cat = this.categories.categories.find(e => e.ntee === 'A')

			if (cat) {
				return cat.title || null
			}
			return null
		},
		subcategory() {
			if (this.institution && this.institution.ntee) {
				return this.institution.ntee.ntee_title
			}
			const subcat = this.categories.subcategories.find(
				e => e.ntee === 'A01'
			)
			if (subcat) {
				return subcat.title || null
			}
			return null
		},
		websiteURL() {
			return this.institution ? this.institution.website_url : null
		},
		address() {
			return this.institution && this.institution.address
				? this.institution.address.formatted_address
				: null
		},
		cityAndState() {
			return this.institution && this.institution.address
				? `${this.institution.address.city}, ${this.institution.address.state}`
				: null
		},
		fullImpactStatement() {
			return this.institution
				? this.institution.full_impact_statement
				: null
		},
		hasOriginalFullImpactStatement() {
			if (
				this.institution &&
				this.institution.full_impact_statement === 'make a difference'
			) {
				return true
			}
			if (
				this.institution &&
				(this.institution.full_impact_statement === '' ||
					this.institution.full_impact_statement === null)
			) {
				return true
			}

			return false
		},
		hasOriginalDescription() {
			if (
				this.institution &&
				this.institution.description.slice(0, 33) ===
					'We are impacting the community of'
			) {
				return true
			}

			if (
				this.institution &&
				(this.institution.description === '' ||
					this.institution.description === null)
			) {
				return true
			}

			return false
		},
		truncatedDescription() {
			return this.institution &&
				this.institution.description &&
				this.institution.description.length > 400
				? this.institution.description.slice(0, 400) + ' ...'
				: this.institution.description
		},
		filteredSubcategories() {
			return this.categories.subcategories.filter(
				e =>
					e.category.category_code ===
					this.editableBrandSections.mission.categoryCode
			)
		},
		formattedAddress() {
			return this.institution.address &&
				this.institution.address.formatted_address
				? this.institution.address.formatted_address
				: ''
		},
		usStates() {
			return states
		},
		showCustomURL() {
			return (
				this.hasLogo &&
				this.hasCoverImage &&
				!this.hasOriginalMissionStatement
			)
		},
		showTutorial() {
			return (
				!this.dismissTutorial &&
				!(
					this.hasLogo &&
					this.hasCoverImage &&
					!this.hasOriginalMissionStatement
				)
			)
		},
		showBrandOnboarding() {
			if (this.hasLogo || this.hasCoverImage) {
				return false
			}
			if (
				window.localStorage.getItem('startedBrandOnboarding') ||
				this.showBrandView
			) {
				return false
			}
			return true
		},
		todoCount() {
			let current = this.hasLogo ? 1 : 0
			current += this.hasCoverImage ? 1 : 0
			if (!this.hasOriginalMissionStatement) {
				current = current + 1
			}

			return `${current}/3`
		},
		hasOriginalMissionStatement() {
			if (window.localStorage.getItem('hasCompletedBrandOnboarding')) {
				return false
			}
			return (
				this.hasOriginalFullImpactStatement &&
				this.hasOriginalDescription
			)
		},
		hasSocialMedia() {
			return !!(
				this.institution.brand.facebook_url ||
				this.institution.brand.instagram_url ||
				this.institution.brand.twitter_url
			)
		},
		hasConctactDetails() {
			return !!(
				this.institution.contact_name || this.institution.contact_email
			)
		},
		impact_statement() {
			return get(this.institution, 'impact_statement', '________________')
		},
		full_impact_statement() {
			return get(
				this.institution,
				'full_impact_statement',
				'________________'
			)
		},
		cover_photo_url() {
			return (
				'https://s3.amazonaws.com/roundup-website/cover/' +
				this.institution.uuid +
				'.jpg'
			)
		},
		deeplink() {
			return `https://roundup.app/${
				this.activeCustomURLType === 'Sign Up' ? 'p' : 'd'
			}/${this.showCustomURL ? this.institution.username : 'placeholder'}`
		}
	},

	methods: {
		...mapActions([
			'uploadLogo',
			'uploadCover',
			'updateInstitution',
			'getInstitutionDetail',
			'updateInstitutionUsername',
			'getCategories',
			'getSubCategoryList'
		]),
		withHttp,
		/**
		 * When the location found
		 * @param {Object} addressData Data of the found location
		 * @param {Object} placeResultData PlaceResult object
		 * @param {String} id Input container ID
		 */
		getAddressData(addressData, placeResultData, id) {
			this.institution.address = addressData
			this.institution.address.google = true
		},
		async confirmAddress() {
			if (!this.$refs.confirmForm.validate()) {
				return
			}

			let contact = this.editableBrandSections.contact
			contact.google = true
			contact.formatted_address = `${get(
				contact,
				'street_number',
				''
			)} ${get(contact, 'street_first', '')} ${get(
				contact,
				'city',
				''
			)}, ${get(contact, 'state', '')} ${get(contact, 'zip', '')}, USA`
			contact.full_address = contact.formatted_address

			this.institution.brand.contact_name = this.editableBrandSections.contact.name
			this.institution.brand.contact_email = this.editableBrandSections.contact.email

			this.editableBrandSections.contact = {
				...this.editableBrandSections.contact,
				...contact
			}
			this.showLocationDetails = false
			this.institution.address = contact
			try {
				await this.update()
				this.closeEditableBrandSections()
			} catch (e) {
				console.log(e)
			}
		},
		async fillBrand() {
			this.editableBrandSections.title.name = this.institution.name
			this.editableBrandSections.mission.fullImpactStatement = this.institution.full_impact_statement
			this.editableBrandSections.mission.description = this
				.hasOriginalDescription
				? null
				: this.institution.description
			this.editableBrandSections.mission.categoryCode = this.institution
				.ntee
				? this.institution.ntee.category.category_code
				: null
			this.editableBrandSections.mission.nteeCode = this.institution.ntee
				? this.institution.ntee.ntee
				: null
			this.editableBrandSections.social.facebookURL = this.institution.facebook_url
			this.editableBrandSections.social.twitterURL = this.institution.twitter_url
			this.editableBrandSections.social.instagramURL = this.institution.instagram_url
			this.editableBrandSections.social.websiteURL = this.institution.website_url
			this.editableBrandSections.contact.state = this.institution.address
				? this.institution.address.state
				: null
			this.editableBrandSections.contact.street_first = this.institution
				.address
				? this.institution.address.street_first
				: null
			this.editableBrandSections.contact.city = this.institution.address
				? this.institution.address.city
				: null
			this.editableBrandSections.contact.zip = this.institution.address
				? this.institution.address.zip
				: null
			this.editableBrandSections.contact.name =
				this.institution.contact_name ||
				this.institution.brand.contact_name
			this.editableBrandSections.contact.email =
				this.institution.contact_email ||
				this.institution.brand.contact_email
		},
		onCategoryChange(categoryNtee) {
			if (this.institution.ntee.ntee.includes(categoryNtee)) {
				this.editableBrandSections.mission.nteeCode = this.institution.ntee.ntee
			} else {
				this.editableBrandSections.mission.nteeCode = null
			}
		},
		onLocationInputFocus() {
			this.institution.address.google = false
		},
		onUploadButtonClick(ref) {
			this.isSelecting = true
			window.addEventListener(
				'focus',
				() => {
					this.isSelecting = false
				},
				{ once: true }
			)

			this.$refs[ref].click()
		},
		generateQRCode() {
			analytics.track('generate_qr_code')
			this.showQRCode = true
			setTimeout(() => {
				this.showQRCode = false
			}, 4000)
		},
		onShowBrandView(v) {
			this.showBrandView = v
		},
		nextTutorial() {
			this.showTutorialModal = false
			this.currentTutorialStep = 1
		},
		closeTutorial() {
			this.currentTutorialStep = -1
		},
		triggerLogoUpload() {
			this.isLogoLoading = true
			const options = {
				uploadInBackground: false,
				transformations: {
					crop: {
						aspectRatio: 1 / 1,
						force: true
					}
				},
				accept: 'image/*',
				onClose: this.onClose,
				onUploadDone: this.onLogoPicked,
				fromSources: [
					'local_file_system',
					'url',
					'imagesearch',
					'googledrive',
					'facebook',
					'dropbox'
				]
			}
			this.filestack.picker(options).open()
		},
		onLogoPicked(res) {
			const file = res.filesUploaded.pop()
			this.uploadLogo(file.url)
				.then(data => {
					this.institution.logo_url = data
					this.isLogoLoading = false
					if (this.hasCoverImage && this.currentTutorialStep === 1) {
						this.currentTutorialStep = 2
					}
				})
				.catch(() => {
					this.setAlert({
						alertText: 'Please try again.',
						showAlert: true,
						alertColor: 'error'
					})
				})
				.finally(() => {
					this.isLogoLoading = false
				})
		},
		triggerCoverUpload() {
			this.isCoverLoading = true
			const options = {
				uploadInBackground: false,
				transformations: {
					crop: {
						aspectRatio: 16 / 9,
						force: true
					}
				},
				accept: 'image/*',
				onClose: this.onClose,
				onUploadDone: this.onCoverPicked,
				fromSources: [
					'local_file_system',
					'url',
					'imagesearch',
					'googledrive',
					'facebook',
					'dropbox'
				]
			}
			this.filestack.picker(options).open()
		},
		onClose() {
			this.isCoverLoading = false
			this.isLogoLoading = false
		},
		onCoverPicked(res) {
			const file = res.filesUploaded.pop()
			this.uploadCover(file.url)
				.then(data => {
					this.institution.image_url = data
					this.isCoverLoading = false
					if (this.hasImages && this.currentTutorialStep === 1) {
						this.currentTutorialStep = 2
					}
				})
				.catch(err => {
					this.isCoverLoading = false
					this.setAlert({
						alertText: 'Please try again.',
						showAlert: true,
						alertColor: 'error'
					})
				})
		},
		appendToFilename(filename, string) {
			var dotIndex = filename.lastIndexOf('.')
			if (dotIndex === -1) return filename + string
			else
				return (
					filename.substring(0, dotIndex) +
					string +
					filename.substring(dotIndex)
				)
		},
		downgradeImage(el) {
			el.srcElement.src = this.institution.image_url
		},
		onlyNumber(evt) {
			const clean = evt.replace(/\D+/g, '')
			if (clean !== evt) {
				this.message = 'Numbers only please :)'
			} else {
				this.message = null
			}
			this.user.phone = clean
		},
		copyToClipboard() {
			navigator.clipboard.writeText(this.deeplink)

			this.copied = true
			setTimeout(() => {
				this.copied = false
			}, 2000)
		},
		goToCustomPage() {
			if (window.analytics) {
				window.analytics.track('navigateToCustomPage')
			}
			if (!this.showCustomURL) {
				this.setAlert({
					alertText:
						'Your page is not live. Please upload a cover photo and logo first. It will then be set live automatically and your donors can begin registering.',
					showAlert: true,
					alertColor: 'error',
					alertTimeout: 5000
				})
			} else {
				window.open(this.deeplink, '_blank')
			}
		},
		closeEditableBrandSections() {
			for (var section in this.editableBrandSections) {
				this.editableBrandSections[section].show = false
			}
			this.fillBrand()
		},
		findSubcategoryID() {
			const subcat = this.filteredSubcategories.find(
				e => e.ntee === this.editableBrandSections.mission.nteeCode
			)
			return subcat ? subcat.id : this.institution.ntee.id
		},
		async saveBrandEdits(section) {
			switch (section) {
				case 'title':
					this.institution.name = this.editableBrandSections.title.name
					break
				case 'mission':
					this.institution.full_impact_statement = this.editableBrandSections.mission.fullImpactStatement
					this.institution.description = this.editableBrandSections.mission.description
					this.institution.ntee_id = this.findSubcategoryID()
					break
				case 'social':
					this.institution.facebook_url = this.editableBrandSections.social.facebookURL
					this.institution.twitter_url = this.editableBrandSections.social.twitterURL
					this.institution.instagram_url = this.editableBrandSections.social.instagramURL
					this.institution.website_url = this.editableBrandSections.social.websiteURL
					break
				case 'contact':
					if (!this.institution.address.google) {
						this.showLocationDetails = true
						return
					}
					this.institution.contact_name = this.editableBrandSections.contact.name
					this.institution.contact_email = this.editableBrandSections.contact.email
					break
				default:
					break
			}
			try {
				await this.update()
				this.closeEditableBrandSections()
			} catch (e) {
				console.log(e)
			}
		},
		socialURLRules(v) {
			if (!v) {
				return true
			}
			const valid = /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
				v
			)

			if (valid) {
				return true
			}

			return 'Please enter a valid url'
		},
		async update() {
			return new Promise((resolve, reject) => {
				this.isLoading = true
				this.updateInstitution(this.institution)
					.then(() => {
						this.showEditModal = false
						this.isLoading = false
						if (
							this.hasLogo &&
							this.hasCoverImage &&
							!this.hasOriginalMissionStatement &&
							!window.localStorage.getItem(
								'hasCompletedBrandOnboarding'
							)
						) {
							this.dismissTutorial = true
							this.setAlert({
								alertText:
									'Your custom URLs have been activated',
								showAlert: true
							})
							window.localStorage.setItem(
								'hasCompletedBrandOnboarding',
								true
							)
						} else if (
							window.localStorage.getItem(
								'hasCompletedBrandOnboarding'
							)
						) {
							this.dismissTutorial = true
						} else {
							this.dismissTutorial = false
						}
						this.setAlert({
							alertText: 'Organization Updated Successfully',
							showAlert: true
						})
						resolve()
					})
					.catch(e => {
						this.showEditModal = false
						this.isLoading = false
						this.setAlert({
							alertText: e.response.data.message,
							showAlert: true,
							alertColor: 'error'
						})
						reject()
					})
			})
		},
		openCustomURLWindow(type = 'p') {
			const url = `https://roundup.app/${type}/${this.newInstitutionUsername}`
			window.open(url, '_blank')
		},
		onCustomURLModalSave() {
			this.updateInstitutionUsername({
				username: this.newInstitutionUsername
			})
				.then(() => {
					this.editCustomURLModal = false
				})
				.catch(
					(
						message = 'Could not update your custom URL. Please try again.'
					) => {
						// throw error alert
						this.setAlert({
							alertText: message,
							showAlert: true,
							alertColor: 'error'
						})
					}
				)
		}
	},
	async mounted() {
		if (!this.hasBrand) {
			this.showTutorialModal = false
		}
		if (
			this.hasLogo &&
			this.hasCoverImage &&
			!this.hasOriginalMissionStatement
		) {
			this.dismissTutorial = true
		}
		await this.getCategories()
		await this.getSubCategoryList()
		this.filestack = filestack.init(process.env.VUE_APP_FILESTACK_KEY)
		this.newInstitutionUsername = this.institution.username
		if (this.institution.address) {
			this.institution.address.google = true
		}
		await this.fillBrand()
	},
	watch: {
		me: {
			handler: function(newV) {
				this.institution = cloneDeep(newV.institution)
				this.fillBrand()
			},
			deep: true
		},
		institution: {
			handler: function(newV) {
				if (
					newV.address.google === undefined ||
					newV.address.google === null
				) {
					newV.google = true
				}
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.onboarding-view {
	width: 100%;
	font-family: 'Work Sans', sans-serif;
	h2 {
		font-size: 24px;
		font-weight: 400;
		color: #484a50;
	}
}

.brand-view {
	.action-left {
		.v-btn:hover:before {
			opacity: 0 !important;
		}
		.v-btn:focus:before {
			opacity: 0 !important;
		}
		.url-button.v-btn.v-btn--disabled {
			background: none !important;
		}
		.fa-info-circle {
			color: #4133a8;
		}
		.custom-url-input.v-input--is-disabled {
			.v-input__slot {
				fieldset {
					background: #f6f6f8 !important;
				}
			}
		}
	}
	.action-right {
		.action-right-button.v-btn.v-btn--disabled {
			background: transparent !important;
			opacity: 0.5;
		}
	}
}

.deep-register-view {
	width: 100%;
	.subtitle {
		margin: 0;
		color: $roundup-medium-grey;
	}

	.cover {
		position: relative;
		margin-bottom: 120px;
		.profile-icon {
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: white;
			padding: 3px;
			border: 1px solid #dcdee3;
			border-radius: 10px;
		}

		.camera-icon {
			position: absolute;
			bottom: -15px;
			left: 125px;
			background: $roundup-bone;
			border-radius: 20px;
			border: 1px solid #747a8d;
		}

		.upload-cover {
			letter-spacing: normal !important;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		.has-upload-cover {
			letter-spacing: normal !important;
			position: absolute;
			bottom: 5%;
			right: 5%;
		}
	}
	.register-images {
		position: relative;
		margin-left: -40px;
		margin-right: -40px;
		margin-top: -30px;
		height: 275px;
		overflow: hidden;
		.add-logo {
			height: 75px;
			max-width: 75px;
			justify-content: center;
			align-items: center;
			display: flex;
			border-radius: 10px;
			border-color: #000;
			border-width: 2px;
			position: absolute;
			left: 40px;
			bottom: 15px;
			border: 2px solid $roundup-light-grey;
			background: #fff;
		}
	}
	.linkRow {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 0px;
		margin-top: 24px;
	}
	.brand-card-body {
		display: flex;
		flex-direction: column;
		align-items: center;

		&-section {
			width: 100%;
			padding: 24px 20px;
		}

		.edit-icon {
			color: #484a50;
		}

		&-row {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		&-icon {
			color: #3770fe;
			margin-right: 16px;
		}

		&-row-title {
			color: #747a8d;
			font-weight: 600;
			font-size: 16px;
			text-transform: uppercase;
		}

		&-title {
			font-family: 'Bitter', serif;
			font-weight: 600;
			font-size: 36px;
			color: #484a50;
			text-align: center;
			width: 100%;
			padding-left: 36px;
		}

		&-description {
			line-height: 1.625;
		}
		.dashed-box {
			cursor: pointer;
			color: #747a8d;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			border: 1px dashed #b9bcc6;
			height: 141px;
		}
	}
	.deep-register-parts {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin: auto;
		padding: 0px 30px 30px 30px;
		margin-bottom: 40px;
	}
	.roundup-button {
		&:disabled {
			background: $roundup-light-grey;
			color: $roundup-medium-grey;
			border: none;
		}
	}
	.deep-register-emotion {
		background: #fff;
		border-radius: 10px;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
		padding: 30px 40px;
		width: 60%;
		flex-shrink: 0;
		@media screen and (max-width: 960px) {
			width: auto;
		}
		@media screen and (max-width: 480px) {
			border-radius: 0px;
		}
		h1 {
			font-family: ff-tisa-web-pro, sans-serif;
		}
		.register-form {
			label {
				display: block;
				margin-top: 5px;
				margin-bottom: 5px;
			}
			.terms {
				font-size: 14px;
				color: #888;
				margin-bottom: 0px;
			}
		}
	}
}
@media screen and (max-width: 960px) {
	// .deep-register-view {
	// 	width: 100%;
	// 	left: 0px;
	// }
	// .deep-register-parts {
	// 	display: block;
	// 	max-width: 540px;
	// }
	// .deep-register-emotion {
	// 	width: auto;
	// }
}
@media screen and (max-width: 480px) {
	.register-images img {
		border-radius: 0px;
	}
	// .deep-register-steps {
	// 	padding: 30px;
	// 	.step {
	// 		vertical-align: top;
	// 		padding: 10px 0px;
	// 	}
	// }
	.deep-register-emotion {
		border-radius: 0px;
	}
	@-webkit-keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
		}
	}
	@-o-keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
		}
	}
	@keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
		}
	}
}

.register-images {
	.add-cover {
		height: 100%;
		max-width: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		border-bottom: 2px solid $roundup-light-grey;
		flex-direction: column;
		text-align: center;
		background: url('../../../assets/Default_CoverPhoto.png');
		background-size: contain;
		background-repeat: no-repeat;
	}

	img {
		border-radius: 10px 10px 0px 0px;
		width: 100%;
		background: #fff;
	}
	.logo {
		max-width: 75px;
		position: absolute;
		left: 40px;
		bottom: 15px;
		border-radius: 10px;
		background: #fff;
	}
	.image-upload {
		position: relative;
	}
	.image-upload-buttons {
		padding: 10px 0px;
	}
}
.deep-register-half {
	padding: 0px 15px;
}

.register-form {
	label {
		display: block;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.terms {
		font-size: 14px;
		color: #888;
		margin-bottom: 0px;
	}
}
.deep-register-steps {
	padding: 30px 10px 30px 30px;
	.step {
		.columns {
			display: flex;
		}
		p {
			margin-top: 5px;
		}
		img {
			width: 25%;
			height: 25%;
		}
	}
}

.profile-information {
	form {
		padding-top: 20px;
	}
	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 40px;
	}
	h3 {
		text-transform: uppercase;
		font-size: 14px;
		margin-bottom: 0px;
		margin-top: 40px;
		color: #71788c;
		letter-spacing: 0;
		color: $roundup-medium-grey;
	}
	p {
		color: $roundup-medium-grey;
		margin-bottom: 0px;
	}
}
.deeplink {
	border-radius: 5px;
	background: $roundup-light-grey;
	margin-right: 10px !important;
	padding: 2px 5px;
	color: $roundup-navy;
	font-size: 16px;
	&.disabled {
		color: $roundup-medium-grey;
	}
}
.image-upload-custom {
	position: absolute;
	bottom: 5px;
	right: 5px;
}
.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	.btn {
		border: 1px solid $roundup-light-grey;
		color: $roundup-navy;
		background-color: #fff;
		border-radius: 10px;
		width: 100%;
		height: 40px;
		font-size: 14px;
		margin: 0px;
		&.loading {
			color: #fff;
			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 0px;
				height: 0px;
				border-radius: 50%;
				left: 45%;
				top: 50%;
				border: 2px solid rgba(255, 255, 255, 0.8);
				border-right: 2px solid $roundup-navy;
				animation: rotate360 0.5s infinite linear,
					exist 0.1s forwards ease;
			}
		}
	}
	&:hover {
		.btn {
			background: $roundup-navy;
			color: $white;
			border-color: $roundup-navy;
			cursor: pointer;
			.loading {
				background: $roundup-navy;
				color: $white;
				border-color: $roundup-navy;
				cursor: pointer;
			}
		}
	}
	input[type='file'] {
		font-size: 100px;
		position: absolute;
		cursor: pointer;
		left: 0;
		top: 0;
		opacity: 0;
	}
	&.grow {
		box-shadow: 0 0 0 0 #000;
		border-radius: 10px;
		transform: scale(1);
		animation: pulse 2s infinite;
		animation-iteration-count: 5;
	}
}
@-moz-keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.tooltip {
	display: flex;
	align-items: center;
	&:hover {
		cursor: pointer;
		.tooltiptext {
			visibility: hidden;
		}
	}
	.tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: $roundup-navy;
		color: #fff;
		text-align: center;
		padding: 5px 0;
		border-radius: 6px;
		position: absolute;
		z-index: 1;
	}
}
.overlay {
	top: 0;
	bottom: -400px;
	right: 0;
	left: 0;
	position: absolute;
	background: rgba(0, 0, 0, 0.5);
	z-index: 100;
}
.show {
	z-index: 101;
	border-radius: 10px;
	background: #fff;
}
.edit_tutorial {
	background: url('../../../assets/Edit_tutorial.png');
	width: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	height: 80px;
}
.edit_img {
	display: inline;
	height: 1.5em;
	margin-top: 1em;
	margin-left: 1em;
}
.brand-card-body-icon {
	height: 18px !important;
}
</style>
